/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    thermometer: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3"/><path pid="1" d="M8 0a2.5 2.5 0 00-2.5 2.5v7.55a3.5 3.5 0 105 0V2.5A2.5 2.5 0 008 0M6.5 2.5a1.5 1.5 0 113 0v7.987l.167.15a2.5 2.5 0 11-3.333 0l.166-.15z"/>',
    },
});
